<template>
  <div :style="{ direction: 'ltr' }">
    <feather-icon
      icon="EyeIcon"
      svg-classes="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="viewRecord"
    />

    <feather-icon
      v-if="$gate.can('edit_users')"
      icon="Edit3Icon"
      svg-classes="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />

    <feather-icon
      v-if="$gate.can('delete_users')"
      icon="Trash2Icon"
      svg-classes="h-5 w-5 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { UserAction } from '@/store/actionTypes';

export default {
  name: 'CellRendererActions',
  methods: {
    ...mapActions('user', [UserAction.remove]),

    viewRecord() {
      this.$router
        .push({
          name: 'users-details',
          params: { userId: this.params.data.id },
        })
        .catch();
    },
    editRecord() {
      if (!this.$gate.can('edit_users')) {
        return;
      }

      this.$router
        .push({ name: 'users-edit', params: { userId: this.params.data.id } })
        .catch();
    },
    async deleteRecord() {
      if (!this.$gate.can('delete_users')) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeUser(this.params.data.id);

        this.showDeleteSuccess();
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a user.');
      }

      this.$vs.loading.close();
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${this.params.data.name}"?`,
        accept: this.deleteRecord,
        acceptText: 'Delete',
      });
    },
    showDeleteSuccess() {
      this.notifySuccess(
        'User deleted',
        'The user has been successfully deleted.'
      );
    },
  },
};
</script>
