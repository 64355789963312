<template>
  <div id="page-user-list">
    <div v-if="!$gate.isKamRole" class="flex flex-wrap-reverse items-center">
      <button-create
        v-if="$gate.can('create_users')"
        title="Add user"
        @click="addNewUser"
      />
    </div>

    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mt-6 mb-6"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Role" />
          <v-select
            v-model="roleFilter"
            :options="userRoleList"
            :clearable="false"
            class="mb-4 md:mb-0"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Status" />
          <v-select
            v-model="statusFilter"
            :options="userStatusList"
            :clearable="false"
            class="mb-4 md:mb-0"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Verified" />
          <v-select
            v-model="isVerifiedFilter"
            :options="userVerificationStatusList"
            :clearable="false"
            class="mb-4 sm:mb-0"
          />
        </div>
      </div>
    </vx-card>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="flex items-center">
            <items-per-page
              :page="currentPage"
              :per-page="paginationPageSize"
              :total="users.length"
              :queried-items="users.length"
              @change="changeItemsPerPage"
            />

            <vs-button
              radius
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-ccw"
              class="ml-3"
              @click="reload"
            ></vs-button>
          </div>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          v-model="searchQuery"
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          placeholder="Search..."
          @input="updateSearchQuery"
        />
        <!-- ACTION - DROPDOWN -->
        <action-dropdown size="3">
          <action-dropdown-item
            v-if="$gate.can('delete_users')"
            icon="TrashIcon"
            title="Delete"
            @click="deleteSelected"
          />
        </action-dropdown>
      </div>

      <ag-grid-vue
        ref="usersTable"
        :components="components"
        :grid-options="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="users"
        row-selection="multiple"
        col-resize-default="shift"
        :animate-rows="true"
        :floating-filter="true"
        :pagination="true"
        :pagination-page-size="paginationPageSize"
        :suppress-pagination-panel="true"
      />

      <vs-pagination v-model="currentPage" :total="totalPages" :max="7" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/app/extraComponents/agGridStyleOverride.scss';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';

import { UserAction } from '@/store/actionTypes';
import { userRoleList } from '@/enums/UserRoleEnum';
import { userStatusList } from '@/enums/UserStatusEnum';
import { userVerificationStatusList } from '@/enums/UserVerificationStatusEnum';

import moduleUser from '@/store/users/moduleUser';

import ActionDropdown from '@/views/components/actions/ActionDropdown';
import ActionDropdownItem from '@/views/components/actions/ActionDropdownItem';
import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import ItemsPerPage from '@/views/components/ItemsPerPage';

import CellRendererLink from './cell-renderer/CellRendererLink';
import CellRendererAvatar from './cell-renderer/CellRendererAvatar';
import CellRendererStatus from './cell-renderer/CellRendererStatus';
import CellRendererRole from './cell-renderer/CellRendererRole';
import CellRendererVerified from './cell-renderer/CellRendererVerified';
import CellRendererActions from './cell-renderer/CellRendererActions';

export default {
  components: {
    ActionDropdown,
    ActionDropdownItem,
    AgGridVue,
    ButtonCreate,
    ItemsPerPage,
    vSelect,

    /* eslint-disable */
    CellRendererLink,
    CellRendererAvatar,
    CellRendererStatus,
    CellRendererRole,
    CellRendererVerified,
    CellRendererActions,
    /* eslint-enable */
  },
  data() {
    return {
      // Filter Options
      roleFilter: { label: 'All', value: 'all' },
      userRoleList,

      statusFilter: { label: 'All', value: 'all' },
      userStatusList,

      isVerifiedFilter: { label: 'All', value: 'all' },
      userVerificationStatusList,

      searchQuery: '',

      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 120,
          minWidth: 120,
          maxWidth: 120,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Avatar',
          field: 'photo',
          width: 90,
          minWidth: 90,
          maxWidth: 120,
          cellRendererFramework: 'CellRendererAvatar',
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 200,
          minWidth: 100,
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: true,
          width: 250,
          minWidth: 120,
        },
        {
          headerName: 'Role',
          field: 'role',
          filter: true,
          width: 150,
          minWidth: 150,
          cellRendererFramework: 'CellRendererRole',
        },
        {
          headerName: 'Mobile',
          field: 'mobile',
          filter: true,
          width: 150,
          minWidth: 120,
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 150,
          minWidth: 100,
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          headerName: 'Verified',
          field: 'is_verified',
          filter: true,
          width: 125,
          minWidth: 125,
          cellRendererFramework: 'CellRendererVerified',
          cellClass: 'text-center',
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 140,
          minWidth: 140,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererAvatar,
        CellRendererStatus,
        CellRendererRole,
        CellRendererVerified,
        CellRendererActions,
      },
    };
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter('role', obj.value);
    },
    statusFilter(obj) {
      this.setColumnFilter('status', obj.value);
    },
    isVerifiedFilter(obj) {
      this.setColumnFilter('is_verified', obj.value);
    },
  },
  computed: {
    ...mapState('user', {
      users: ({ users }) => users,
    }),

    paginationPageSize: {
      get() {
        if (this.gridApi) {
          return this.gridApi.paginationGetPageSize();
        }

        return 10;
      },
      set(val) {
        this.gridApi.paginationSetPageSize(val);
      },
    },
    totalPages() {
      if (this.gridApi) {
        return this.gridApi.paginationGetTotalPages();
      }

      return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) {
          return this.gridApi.paginationGetCurrentPage() + 1;
        }

        return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    ...mapActions('user', [UserAction.fetch, UserAction.remove]),

    registerStoreModules() {
      if (!moduleUser.isRegistered) {
        this.$store.registerModule('user', moduleUser);
        moduleUser.isRegistered = true;
      }
    },
    changeItemsPerPage(val) {
      this.paginationPageSize = val;
    },
    fitTable() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    addNewUser() {
      if (!this.$gate.can('create_users')) {
        return;
      }

      this.$router.push({ name: 'users-create' }).catch();
    },
    async reload() {
      this.$vs.loading();

      try {
        await this.fetchUsers();

        this.notifySuccess(
          'User list refreshed',
          'The user list has been successfully refreshed.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with loading users.');
      }

      this.$vs.loading.close();
    },
    deleteSelected() {
      const ids = this.gridApi.getSelectedRows().map((item) => item.id);

      if (ids.length) {
        this.confirmDeleteAll(ids);
      }
    },
    confirmDeleteAll(ids) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete ${ids.length} selected users?`,
        accept: () => this.deleteAll(ids),
        acceptText: 'Delete',
      });
    },
    async deleteAll(ids) {
      this.$vs.loading();

      try {
        await Promise.all(ids.map((id) => this.removeUser(id)));

        this.notifySuccess(
          'Selected users deleted',
          'Selected users has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting users.');
      }

      this.$vs.loading.close();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.fitTable();

    this.$nextTick(function () {
      window.addEventListener('resize', this.fitTable);
      window.addEventListener('resize', this.fitTable);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.fitTable);
    window.removeEventListener('resize', this.fitTable);
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchUsers();
    } catch (error) {
      this.notifyError(error, 'Problem with loading users.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
